import { Icon } from '@design-system';
import Link from 'next/link';
import React, { FC } from 'react';

import { Ratio } from '../../helpers/images';
import { AspectRatio } from '../AspectRatio/AspectRatio';
import { Image } from '../Image';

export interface MediaCardProps {
  href: string;
  image: string;
  onClick: () => void;
  title: string;
  variant: Ratio;
}

export const MediaCard: FC<MediaCardProps> = ({
  href,
  image,
  onClick,
  title,
  variant,
}) => (
  <Link
    className="group block h-full overflow-hidden rounded-sm"
    href={href}
    onClick={onClick}
    prefetch={false}
  >
    <AspectRatio
      className="h-full"
      lg={variant === '3/2' ? '3/2' : '3/4'}
      md={variant === '3/2' ? '4/3' : '2/3'}
      sm="4/3"
    >
      <div className="flex w-full flex-col justify-end p-6">
        <div className="absolute inset-0 overflow-hidden">
          <Image
            alt={title}
            className="h-full overflow-hidden"
            hoverEffect
            size={{
              sm: { ratio: '4/3', span: 12 },
              md: { ratio: '3/4', span: 3 },
              lg: { ratio: '3/2', span: 3 },
            }}
            src={image}
          />
        </div>
        <div className="absolute inset-0 bg-neutral-6/10 transition-colors duration-300 group-hover:bg-neutral-6/40" />
        <div className="z-10 flex flex-col items-start space-y-3">
          <h3 className="max-w-60 text-md font-bold text-white lg:text-lg">
            {title}
          </h3>
          <div className="rounded-full bg-white text-neutral-6">
            <Icon className="m-3" name="ic_arrow_top_right" size="md" />
          </div>
        </div>
      </div>
    </AspectRatio>
  </Link>
);
