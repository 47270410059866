import Link from 'next/link';
import { FC } from 'react';

import { Image } from '../Image';

interface LinkCardImageProps {
  className?: string;
  card: LinkCardImageComponent;
  onClick: () => void;
}

export const LinkCardImage: FC<LinkCardImageProps> = ({ card, onClick }) => (
  <Link
    className="group relative block w-full"
    href={card.link.url}
    onClick={onClick}
    target={card.link.linktype === 'story' ? '_self' : '_blank'}
  >
    <Image
      alt={card.title}
      hoverEffect
      size={{
        sm: { ratio: '4/3', span: 12 },
        md: { ratio: '4/3', span: 6 },
        lg: { ratio: '4/3', span: 6 },
      }}
      src={card.image.filename}
    />
    <div className="pointer-events-none absolute inset-0 bg-neutral-6/40">
      <div className="flex h-full flex-col justify-end p-4">
        <div className="w-full max-w-md text-white md:text-md lg:text-lg">
          <div>{card.subtitle}&nbsp;</div>
          <div className="font-bold">{card.title}</div>
        </div>
      </div>
    </div>
  </Link>
);
