import { Button } from '@design-system';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useHubspotChat } from '../../hooks/useHubspotChat';
import {
  TrackLinkCardCtaClicked,
  TrackLinkCardSalesCtaClicked,
} from '../../utils/analytics';
import { renderStoryblokRichText } from '../../utils/renderStoryblokRichText';

export interface LinkCardCtaProps {
  headline: string;
  subHeadline?: StoryblokTextAreaType;
  ctaLink?: string;
  ctaText?: string;
}

const LinkCardCta: React.FC<LinkCardCtaProps> = (props) => {
  const { ctaLink, ctaText, headline, subHeadline } = props;

  const { openHandler } = useHubspotChat();
  const { t } = useTranslation('linkCards');
  const preparedCtaText =
    ctaText || t('cta.ctaTextDefault') || 'Contact support';

  return (
    <div className="link-card cta absolute inset-0 items-end justify-between rounded-md bg-neutral-2 p-4 sm:p-6 md:flex-col md:justify-end">
      <div
        className={classNames(
          'flex flex-col-reverse md:row-span-2 md:row-start-1',
        )}
      >
        <div className="text-md font-bold md:text-lg">{headline}</div>
        {subHeadline && (
          <div className="mb-3 text-md font-bold text-neutral-4 md:mb-4 md:text-lg">
            {renderStoryblokRichText(subHeadline)}
          </div>
        )}
      </div>
      <div className="row-start-2 md:col-start-1 md:row-start-3">
        {ctaLink && (
          <a className="inline-block min-w-max" href={ctaLink}>
            <Button
              label={preparedCtaText}
              onClick={() => TrackLinkCardCtaClicked({ cta: headline })}
              size="md"
              variant="primary"
            />
          </a>
        )}
        {!ctaLink && (
          <div className="inline-block min-w-max">
            <Button
              label={preparedCtaText}
              onClick={() => {
                openHandler();
                TrackLinkCardSalesCtaClicked({ cta: headline });
              }}
              size="md"
              variant="primary"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkCardCta;
