import { Icon } from '@design-system';
import Link from 'next/link';
import React from 'react';

import { ImageSizes } from '../../helpers/images';
import { LinkCardPlacement } from '../../types/Analytics';
import { VimeoPlugin } from '../../types/Vimeo';
import { TrackLinkCardClicked } from '../../utils/analytics';
import { renderStoryblokRichText } from '../../utils/renderStoryblokRichText';
import BackgroundVideo from '../BackgroundVideo';
import { Image } from '../Image';

export interface MediaImage {
  type: 'image';
  url: string;
  alt: string;
  focus?: string;
}

export interface MediaVideo {
  type: 'video';
  asset: VimeoPlugin;
}

export interface LinkCardMediaProps {
  analyticsPlacement?: LinkCardPlacement;
  headline: string;
  subHeadline?: StoryblokTextAreaType;
  link: string;
  media: MediaImage | MediaVideo;
  imageSizes: ImageSizes;
}

const LinkCardMedia: React.FC<LinkCardMediaProps> = ({
  analyticsPlacement,
  headline,
  imageSizes,
  link,
  media,
  subHeadline,
}) => {
  return (
    <Link
      className="link-card media group absolute inset-0 flex flex-col rounded-sm bg-white text-white"
      href={link}
      onClick={() =>
        TrackLinkCardClicked({
          placement: analyticsPlacement,
          url: link,
          title: headline,
        })
      }
      prefetch={false}
    >
      <div className="absolute inset-0">
        {media.type === 'image' && (
          <Image
            alt={media.alt}
            className="h-full w-full"
            hoverEffect
            size={imageSizes}
            src={media.url}
          />
        )}

        {media.type === 'video' && (
          <BackgroundVideo
            className="h-full w-full object-cover"
            vimeo={media.asset}
          />
        )}

        <div className="absolute inset-0 z-30 bg-neutral-6/10 transition-colors duration-300 group-hover:bg-neutral-6/40" />
      </div>
      <div className="z-40 flex w-full flex-1 flex-col items-start justify-end space-y-3 p-4 sm:p-6">
        {subHeadline && (
          <div className="mb-3 text-md font-bold text-neutral-4 md:mb-4 md:text-lg">
            {renderStoryblokRichText(subHeadline)}
          </div>
        )}
        <div className="z-40 text-md font-bold text-white md:text-lg">
          {headline}
        </div>
        <div className="rounded-full bg-white text-neutral-6">
          <Icon className="m-3" name="ic_arrow_top_right" size="md" />
        </div>
      </div>
    </Link>
  );
};

export default LinkCardMedia;
