import { Button } from '@design-system';
import React, { FC } from 'react';

import { AspectRatio } from '../AspectRatio/AspectRatio';

export interface ButtonCardProps {
  onClick: () => void;
  text: string;
  title: string;
  variant: '3/2' | '3/4' | '4/3';
}

export const ButtonCard: FC<ButtonCardProps> = ({
  onClick,
  text,
  title,
  variant,
}) => (
  <AspectRatio
    className="h-full overflow-hidden rounded-sm"
    lg={variant === '3/2' ? '3/2' : '3/4'}
    md={variant === '3/2' ? '4/3' : '2/3'}
    sm="4/3"
  >
    <div className="flex h-full w-full flex-col justify-end bg-neutral-2 p-6">
      <div className="flex flex-col items-start gap-3">
        <h3 className="text-md font-bold lg:text-lg">{title}</h3>
        <div className="inline-block">
          <Button fullWidth label={text} onClick={onClick} variant="primary" />
        </div>
      </div>
    </div>
  </AspectRatio>
);
