import { Icon } from '@design-system';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import { LinkCardPlacement } from '../../types/Analytics';
import { TrackLinkCardClicked } from '../../utils/analytics';
import { renderStoryblokRichText } from '../../utils/renderStoryblokRichText';

export interface LinkCardTextProps {
  analyticsPlacement?: LinkCardPlacement;
  headline: string;
  subHeadline?: StoryblokTextAreaType;
  link: string;
}

const LinkCardText: React.FC<LinkCardTextProps> = (props) => {
  const { analyticsPlacement, headline, link, subHeadline } = props;
  const hasSubHeadline = Boolean(subHeadline);

  return (
    <Link
      className={classNames(
        'link-card group absolute inset-0 flex flex-col items-start justify-end rounded-sm text-white',
        { 'bg-neutral-6': hasSubHeadline },
        { 'bg-neutral-1': !hasSubHeadline },
      )}
      href={link}
      onClick={() =>
        TrackLinkCardClicked({
          placement: analyticsPlacement,
          url: link,
          title: headline,
        })
      }
      prefetch={false}
    >
      <div className="z-40 flex w-full flex-1 flex-col items-start justify-end space-y-3 p-4 sm:p-6">
        {subHeadline && (
          <div className="mb-3 text-md font-bold text-neutral-4 md:mb-4 md:text-lg">
            {renderStoryblokRichText(subHeadline)}
          </div>
        )}
        <div className="z-40 text-md font-bold text-white md:text-lg">
          {headline}
        </div>
        <div className="rounded-full bg-white text-neutral-6">
          <Icon className="m-3" name="ic_arrow_top_right" size="md" />
        </div>
      </div>
      <div className="absolute inset-0 z-30 bg-neutral-6/10 transition-colors duration-300 group-hover:bg-neutral-6/40" />
    </Link>
  );
};

export default LinkCardText;
